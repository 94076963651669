.multi-page {
	.inner-wrapper {
		.services.wrap {
			position: relative;
			height: 100vh;
			width: 100vw;
			background: $brand-color;
			padding-right: 100px;
			@include media('<=1000px'){
				padding-left: 60px;
			}
			@include media('<=767px'){
				padding: 0;
			}
			.padding {
				width: 100%;
				height: 100%;
				position: relative;
				display: block;
			}
			.serv-wrapper {
				display: block;
				width: 50%;
				position: absolute;
				transition: all 0.4s ease-in-out;
				.item {
					height: 100vh;
					position: relative;
					@include media('<=767px'){
						padding-bottom: 150px;
					}
					> .wrap {
						height: 100%;
						position: relative;
						.num {
							position: absolute;
							width: 100%;
							height: 100%;
							.inner {
								display: table;
								text-align: center;
								width: 100%;
								height: 100%;
								overflow: hidden;
								div {
									display: table-cell;
									vertical-align: middle;
									// font-family: $head-font-stack;
									color: #ffe349;
									font-size: 400px;
									@include media('<=1400px'){
										font-size: 300px;
									}
									@include media('<=1200px'){
										font-size: 250px;
									}
									@include media('<=767px'){
										font-size: 150px;
									}
									@include media('<=320px'){
										font-size: 125px;
									}
								}
							}
						}
						.content {
							display: flex;
							width: 100%;
							height: 100%;
							align-items: center;
							position: relative;
							z-index: 2;
							color: #000;
							.wrap {
								width: 350px;
								margin: 0 auto;
								@include media('<=1400px'){
									width: 300px;
								}
								@include media('<=1200px'){
									width: 230px;
								}
								@include media('<=767px'){
									width: auto;
									padding: 0 10px;
								}
								.title-wrap {
									display: flex;
									align-items: center;
									.icon {
										img {
											@include media('<=1200px'){
												transform: scale(.7);
											}
											@include media('<=767px'){
												transform: scale(.5);
											}
											@include media('<=320px'){
												transform: scale(.4);
											}
										}
									}
									.title {
										font-size: 24px;
										font-family: $head-font-stack;
										margin-left: 25px;
										@include media('<=1400px'){
											font-size: 20px;
										}
										@include media('<=1200px'){
											font-size: 18px;
											margin-left: 15px;
										}
										@include media('<=767px'){
											margin: 0;
											font-size: 16px;
										}
										@include media('<=320px'){
											font-size: 12px;
										}
									}
								}
								p {
									font-size: 14px;
									line-height: 2.143em;
									@include media('<=1200px'){
										font-size: 13px;
									}
									@include media('<=767px'){
										font-size: 10px;
									}
									@include media('<=320px'){
										margin-top: 0;
									}
								}
							}
						}
					}
				}
			}
			.serv-wrapper-left {
				left: 0;
				top: -200%;
				display: flex;
				flex-direction: column-reverse;
			}
			.serv-wrapper-right {
				right: 0;
				top: 0;
			}
		}
		&.services {
			margin-left: -175vw;
			@include media('<=1000px'){
				margin-left: -200vw;
			}
			&.serv2 {
				.serv-wrapper-left {
					top: -100%;
				}
				.serv-wrapper-right {
					top: -100%;
				}
			}
			&.serv3 {
				.serv-wrapper-left {
					top: 0;
				}
				.serv-wrapper-right {
					top: -200%;
				}
			}
		}
	}
}