.multi-page {
	overflow: hidden;
	.inner-wrapper {
		display: inline-flex;
		flex-direction: row;
		transition: all .9s ease-in-out;
		color: #fff;
		.about-1 {
			width: 75vw;
			padding-left: 150px;
			@include media('<=1200px'){
				padding-left: 100px;
			}
			@include media('<=1000px'){
				padding-right: 100px;
				width: 100vw;
			}
			@include media('<=767px'){
				padding: 0 30px;
			}
			.wrapper {
				margin: 0;
				display: flex;
				height: 100%;
				.inner {
					align-self: center;
					@include media('<=767px'){
						align-self: flex-start;
					}
					.heading {
						width: 100%;
						h2 {
							font-size: 48px;
							@include media('<=1400px'){
								font-size: 40px;
							}
							@include media('<=1200px'){
								font-size: 35px;
								margin-top: 100px;
							}
							@include media('<=767px'){
								font-size: 30px;
							}
							@include media('<=380px'){
								font-size: 28px;
							}
							@include media('<=320px'){
								font-size: 20px;
							}
						}
					}
					.sub-wrap {
						display: inline-flex;
						@include media('<=767px'){
							display: flex;
							flex-direction: column;
						}
						.sub {
							width: 50%;
							padding-right: 50px;
							@include media('<=1200px'){
								width: 40%;
								padding-right: 25px;
							}
							@include media('<=767px'){
								width: 100%;
								padding: 0;
							}
							h3 {
								font-family: $text-font-stack;
								font-size: 24px;
								letter-spacing: 1px;
								font-weight: normal;
								@include media('<=1200px'){
									font-size: 20px;
								}
								@include media('<=767px'){
									font-size: 18px;
								}
								@include media('<=320px'){
									font-size: 15px;
								}
								span {
									color: $brand-color;
									font-weight: bold;
								}
							}
						}
						.desc {
							width: 50%;
							@include media('<=1200px'){
								width: 60%;
							}
							@include media('<=767px'){
								width: 100%;
							}
							h5 {
								color: $brand-color;
								font-family: $text-font-stack;
								font-size: 18px;
								margin-bottom: 20px;
								text-transform: uppercase;
								@include media('<=1200px'){
									font-size: 16px;
								}
								@include media('<=767px'){
									font-size: 14px;
								}
								@include media('<=380px'){
									margin-top: 10px;
								}
								@include media('<=320px'){
									font-size: 12px;
								}
							}
							p {
								font-size: 14px;
								line-height: 2.143em;
								@include media('<=767px'){
									font-size: 12px;
								}
							}
						}
					}
				}
			}
		}
		&.about-2 {
			margin-left: -75vw;
			@include media('<=1000px'){
				margin-left: -100vw;
			}
		}
		.about-2 {
			width: 100vw;
			padding-right: 150px;
			display: inline-flex;
			@include media('<=1000px'){
				display: flex;
				flex-direction: column;
				padding-right: 100px;
				padding-left: 100px;
			}
			@include media('<=767px'){
				padding: 100px 30px 0 30px;
			}
			.img-wrap {
				padding: 150px 50px 150px 150px;
				height: 100vh;
				@include media('<=1400px'){
					padding-bottom: 80px;
					padding-right: 30px;
				}
				@include media('<=1200px'){
					padding-left: 100px;
				}
				@include media('<=1000px'){
					height: auto;
					padding: 150px 0 0 0;
					text-align: center;
				}
				@include media('<=767px'){
					padding: 0;
				}
				img {
					height: 100%;
					object-fit: contain;
					max-width: 50vw;
					@include media('<=1200px'){
						max-width: 450px;
					}
					@include media('<=1000px'){
						max-width: 100%;
						margin: 0 auto;
					}
					@include media('<=767px'){
						height: auto;
					}
					@include media('<=380px'){
						max-width: 250px;
					}
					@include media('<=320px'){
						max-width: 150px;
					}
				}
			}
			.content-wrap {
				align-self: center;
				h3 {
					font-size: 36px;
					@include media('<=1200px'){
						font-size: 30px;
					}
					@include media('<=767px'){
						font-size: 25px;
					}
					@include media('<=380px'){
						font-size: 23px;
					}
					@include media('<=320px'){
						font-size: 20px;
					}
				}
				button {
					color: #fff;
					padding-right: 80px;
					margin-top: 50px;
					@include media('<=1200px'){
						margin-top: 30px;
					}
					@include media('<=767px'){
						margin-top: 10px;
					}
				}
			}
		}
	}
}