// -----------------------------------------------------------------------------
// This file contains styles that are specific to the home page.
// -----------------------------------------------------------------------------
.home {
  @include media("<=1000px") {
    background: $brand-color;
  }
  .home-wrapper {
    display: inline-flex;
    width: 100%;
    @include media("<=1000px") {
      display: flex;
      flex-direction: column-reverse;
    }
    @include media("<=767px") {
      padding-top: 80px;
    }
    .left-col {
      width: 50%;
      padding-left: 200px;
      padding-right: 100px;
      align-self: center;
      @include media("<=1300px") {
        padding-left: 100px;
      }
      @include media("<=1000px") {
        width: 100%;
        padding: 0 50px;
        color: #fff;
      }
      @include media("<=767px") {
        padding: 0 15px;
      }
      h1 {
        font-size: 36px;
        text-transform: none;
        @include media("<=1300px") {
          font-size: 33px;
        }
        @include media("<=1024px") {
          font-size: 30px;
        }
        @include media("<=1000px") {
          // text-shadow: 1px 1px 1px #000;
          color: #000;
        }
        @include media("<=767px") {
          font-size: 25px;
        }
        @include media("<=380px") {
          font-size: 23px;
        }
      }
      p {
        @include media("<=1000px") {
          // text-shadow: 1px 1px 1px #000;
          color: #000;
        }
      }
      button {
        padding-right: 80px;
        margin-top: 100px;
        color: #000;
        @include media("<=1400px") {
          margin-top: 50px;
        }
        @include media("<=1000px") {
          font-size: 18px;
        }
        @include media("<=767px") {
          margin-top: 20px;
        }
      }
    }
    .right-col {
      width: 50%;
      @include media("<=1000px") {
        width: 100%;
      }
      > div {
        @include media("<=1000px") {
          text-align: center;
        }
        @include media("<=767px") {
          padding: 0 15px;
        }
        @include media("<=380px") {
          padding: 0 30px;
        }
        img {
          width: 90vh;
          height: 90vh;
          margin-left: -50px;
          @include media("<=1000px") {
            width: 500px;
            height: auto;
            margin: 0 auto;
          }
          @include media(">767px") {
            &.mobile {
              display: none;
            }
          }
          @include media("<=767px") {
            width: 100%;
            display: none;
            &.mobile {
              display: block;
            }
          }
          @include media("<=320px") {
            max-width: 200px;
          }
        }
      }
    }
  }
}
