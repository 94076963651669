.sidebar {
	width: 100px;
    height: 100%;
    background: $black-color;
    position: absolute;
    z-index: 999;
    right: 0;
    @include media('<=767px'){
        background: transparent;
        border: none;
        height: 100px;
    }
    .info-section {
        margin-top: 150px;
        // text-align: center;
        color: #ffffff;
        text-transform: uppercase;
        font-weight: bold;
        letter-spacing: 2px;
        transform: rotate(-90deg);
        transition: all .5s ease-in-out;
        opacity: 0;
        direction: rtl;
        @include media('<=767px'){
            display: none;
        }
        &.active {
            opacity: 1;
        }
    }
    .pagination {
        position: absolute;
        bottom: 20px;
        color: #fff;
        text-align: center;
        width: 100%;
        @include media('<=767px'){
            display: none;
        }
        ul {
            list-style: none;
            padding: 0;
            margin: 0;
            display: flex;
            flex-direction: column-reverse;
            background: $black-color;
            li {
                transition: all .5s ease-in-out;
                margin-top: 10px;
                font-size: 18px;
                &.line {
                    position: absolute;
                    height: 100px;
                    width: 100%;
                    bottom: 38px;
                    &::before {
                        content: ' ';
                        display: block;
                        width: 2px;
                        height: 100px;
                        background-color: $brand-color;
                        position: absolute;
                        left: 50%;
                    }
                }
            }
            &.home {
                li.one {
                    color: $brand-color;
                    margin-top: 128px;
                }
            }
            &.work {
                li.two {
                    color: $brand-color;
                    margin-top: 128px;
                }
                li.line {
                    bottom: 76px;
                }
            }
            &.about {
                li.three {
                    color: $brand-color;
                    margin-top: 128px;
                }
                li.line {
                    bottom: 114px;
                }
            }
            &.services {
                li.four {
                    color: $brand-color;
                    margin-top: 128px;
                }
                li.line {
                    bottom: 152px;
                }
            }
            &.technologies {
                li.five {
                    color: $brand-color;
                    margin-top: 128px;
                }
                li.line {
                    display: none;
                }
            }
        }
    }
}