/**
 * Basic typography style for copy text
 */
body {
	background: $black-color;
	color: $text-color;
	font: normal 18px / 1.6 $text-font-stack;
	@include media('<=1200px'){
		font-size: 14px;
	}
	@include media('<=380px'){
		font-size: 12px;
	}
}
h1, h2, h3, h4, h5 {
	font-family: $head-font-stack;
	// text-transform: uppercase;
	line-height: 1.33em;
	letter-spacing: 2px;
}
h1 {
	font-family: $head-font-stack;
	font-size: 72px;
}
