/*
 * MENU BUTTON
 */
 .menu__toggler {
	position: absolute;
	top: 15px;
	right: 25px;
	z-index: 999;
	height: 50px;
	width: 50px;
	outline: none;
	cursor: pointer;
	display: flex;
	align-items: center;
	background-color: $black-color;
	span,
	span::before,
	span::after {
	  position: absolute;
	  content: '';
	  width: 30px;
	  height: 5px;
	  left: 0;
	  background:$brand-color;
	  transition: 500ms cubic-bezier(0.77, 0, 0.175, 1);
	}

	span {
		left: 10px;
	}
	
	span::before {
	  top: -12px;
	}
	
	span::after {
	  top: 12px;
	}
	
	&.active > span  {
	  background: transparent;
	}
  
	&.active > span::before,
	&.active > span::after {
	  background: $brand-color;
	  top: 0px;
	}
  
	&.active > span::before  {
	  transform: rotate(-225deg);
	}
  
	&.active > span::after  {
	  transform: rotate(225deg);
	}
}


 .menu {
	position: fixed;
	top: 0;
	left: 0;
	opacity: 0;
	z-index: -1;
	color: #fff;
	// background: rgba($brand-color,.7);
	background: $black-color;
	width: 100%;
	height: 100%;
	padding: 100px;
	padding-right: 200px;
	transition: all .3s ease-in-out;
	@include media('<=767px'){
		padding: 100px 50px;
	}
	@include media('<=380px'){
		padding: 50px;
	}
	&.active {
	  opacity: 1;
	  z-index: 998;
	}
	p {
	//   font-size: 1.4rem;
	  margin-bottom: 25px;
	}
	.wrap {
		display: flex;
		width: 900px;
		margin: 0 auto;
		height: 100%;
		align-items: center;
		@include media('<=1200px'){
			width: 100%;
		}
		@include media('<=767px'){
			flex-direction: column;
			align-items: flex-start;
			width: 100%;
		}
		.nav-wrap {
			margin-right: auto;
			@include media('<=767px'){
				width: 100%;
				margin-bottom: 30px;
				padding-bottom: 30px;
				border-bottom: 1px solid #fff;
			}
			.navigation {
				padding: 0;
				margin: 0;
				li {
					list-style: none;
					margin-bottom: 35px;
					@include media('<=767px'){
						margin-bottom: 20px;
					}
					&:last-child {
						margin-bottom: 0;
					}
					button {
						font-family: $head-font-stack;
						font-size: 48px;
						line-height: 1em;
						color: #fff;
						transition: color .3s ease-in-out;
						@include media('<=1200px'){
							font-size: 40px;
						}
						@include media('<=767px'){
							font-size: 35px;
						}
						@include media('<=380px'){
							font-size: 30px;
						}
					}
					&:hover {
						button {
							color: $brand-color;
						}
					}
				}
			}
		}
		.contact-Wrapper {
			h4 {
				font-size: 36px;
				margin: 0 0 25px 0;
				@include media('<=1200px'){
					font-size: 30px;
				}
				@include media('<=767px'){
					font-size: 25px;
				}
			}
			span {
				color: $brand-color;
			}
		}
	}
}

.prev-next-navigation {
	position: fixed;
	top: 0;
	left: 0;
	height: 100%;
	width: 100px;
	@include media('<=767px'){
		top: auto;
		bottom: 0;
		width: 100%;
		height: 60px;
	}
	.wrap {
		position: fixed;
		top:50%;
		width: 60px;
		margin-top: -80px;
		@include media('<=767px'){
			top: auto;
			margin: 0;
			width: 100%;
        }
		button {
			-webkit-appearance: none;
			background: $black-color;
			border: none;
			cursor: pointer;
			width: 60px;
			height: 80px;
			background-repeat: no-repeat;
			background-position: center center;
			font-size: 1px;
			color: #000;
			transition: all .5s ease-in-out;
			@include media('<=767px'){
				height: 60px;
				width: 50%;
			}
			&.disabled {
				cursor: default;
			}
		}
	}
}
body.dark {
	.prev-next-navigation {
		.wrap {
			button {
				background-color: $brand-color;
			}
		}
	}
}