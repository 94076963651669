// -----------------------------------------------------------------------------
// When having several themes, this file contains everything related to the
// default one.
// -----------------------------------------------------------------------------

.loader:empty {
	position: absolute;
	top: calc(50% - 4em);
	left: calc(50% - 4em);
	width: 6em;
	height: 6em;
	border: 1.1em solid rgba(0, 0, 0, 0.2);
	border-left: 1.1em solid #ffda0d;
	border-radius: 50%;
	animation: load8 1.1s infinite linear;
}
  
@keyframes load8 {
	0% {
	  transform: rotate(0deg);
	}
	100% {
	  transform: rotate(360deg);
	}
}

.portrait-mode,
.landscape-mode {
	display: none;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	width: 100vw;
	height: 100vh;
	background: $black-color;
	z-index: 99999999;
	color: #fff;
	text-align: center;
	align-items: center;
	> div {
		margin: 0 auto;
		img {
			max-width: 250px;
			margin: 0 auto;
			@include media('<=767px'){
				max-width: 150px;
			}
		}
		p {
			margin: 20px auto 0 auto;
			font-size: 25px;
			@include media('<=767px'){
				font-size: 20px;
			}
		}
	}
}

#logo {
	position: absolute;
	top: 50px;
	left: 50px;
	@include media('<=767px'){
		top: 12px;
		left: 15px;
	}
	img {
		position: absolute;
		transition: all 0.5s ease-in-out;
		@include media('<=767px'){
            transform: scale(.8);
        }
	}
	.logo2 {
		opacity: 0;
	}
}
body.dark {
	#logo {
		.logo1 {
			opacity: 0;
		}
		.logo2 {
			opacity: 1;
		}
	}
}

.wrapper {
	margin-right: 100px;
	@include media('<=767px'){
		margin-right: 0;
	}
}

.fp-controlArrow {
	display: none;
}

.img-slide-transform {
	transition: all .5s ease-in-out;
	transform: scale(.7);
}
.section.active .img-slide-transform {
	transform: scale(1);
}

#move-bg {
	position: absolute;
	z-index: -1;
	width: 100%;
	left: 0;
	top: 0;
	height: 100%;
	box-sizing: border-box;
	padding-right: 100px;
	@include media('<=1000px'){
		display: none;
	}
	@include media('<=767px'){
		padding-right: 0;
	}
	.inner {
		width: 50%;
		height: 100%;
		background: $brand-color;
		transition: all .9s ease-in-out;
		@include media('<=1000px'){
			// width: 80%;
		}
	}
	&.active .inner {
		margin-left: 50%;
	}
	&.active.push .inner {
		margin-left: 100%;
	}
}

// body.fp-viewing-0-0 {
// 	#move-bg {
// 		@include media('<=1200px'){
// 			display: none;
// 		}
// 	}
// }

.fp-tableCell {
	@include media('<=767px'){
		vertical-align: top !important;
	}
}

// disable portrait mode for some device
// iPad 1, 2, Mini and Air
@media only screen 
  and (min-device-width: 768px) 
  and (max-device-width: 1024px) 
  and (orientation: portrait) 
  and (-webkit-min-device-pixel-ratio: 1) {
	.landscape-mode {
		display: flex;
	}
}
// iPad 3, 4 and Pro 9.7"
@media only screen 
  and (min-device-width: 768px) 
  and (max-device-width: 1024px) 
  and (orientation: portrait) 
  and (-webkit-min-device-pixel-ratio: 2) {
	.landscape-mode {
		display: flex;
	}
}
// iPad Pro 10.5"
@media only screen 
  and (min-device-width: 834px) 
  and (max-device-width: 834px) 
  and (orientation: portrait) 
  and (-webkit-min-device-pixel-ratio: 2) {
	.landscape-mode {
		display: flex;
	}
}
// iPad Pro 12.9"
@media only screen 
  and (min-device-width: 1024px) 
  and (max-device-width: 1024px) 
  and (orientation: portrait) 
  and (-webkit-min-device-pixel-ratio: 2) {
	  .landscape-mode {
		  display: flex;
	  }
}

// disable landscape mode for smartphone
// smartphone start from 767 pixel and less
@media only screen
  and (min-device-width: 200px) 
  and (max-device-width: 850px) 
  and (orientation: landscape) {
	  .portrait-mode {
		  display: flex;
	  }
  }