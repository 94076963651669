.multi-page {
	.inner-wrapper {
		.technologies {
			background: #fff;
			width: 100vw;
			.tech-wrap {
				display: flex;
				height: 100%;
				align-items: flex-end;
				padding: 150px 0 100px 0;
				@include media('<=1600px'){
					padding-bottom: 50px;
				}
				// for ipad or tablet
				@include media('>1000px', '<1400px', 'height>680px'){
					padding-bottom: 150px;
				}
				// for ipad pro
				@include media('>1000px', '<1400px', 'height>900px'){
					padding-bottom: 250px;
				}
				@include media('<=1000px'){
					padding-bottom: 200px;
				}
				@include media('<=767px'){
					padding-bottom: 300px;
				}
				@include media('<=767px', 'height<750px'){
					padding-bottom: 200px;
				}
				h4 {
					// align-self: flex-start;
					color: #000;
					width: 100vw;
					padding-right: 100px;
					text-align: center;
					position: absolute;
					top: 150px;
					font-size: 36px;
					margin: 50px 0 0 0;
					@include media('<=1600px'){
						margin-top: 0;
					}
					@include media('>1000px', '<1400px', 'height>680px'){
						margin-top: 50px;
					}
					@include media('<=1300px'){
						font-size: 30px;
					}
					@include media('<=1024px'){
						font-size: 27px;
					}
					@include media('<=767px'){
						padding: 0 10px;
						font-size: 22px;
					}
					@include media('<=767px', 'height<750px'){
						top: 120px;
					}
					br {
						@include media('<=767px'){
							display: none;
						}
					}
				}
				.tech-list {
					display: inline-flex;
					flex-direction: row;
					padding-left: 200px;
					transition: all 0.9s ease-in-out;
					@include media('<=1000px'){
						padding-left: 100px;
					}
					@include media('<=767px'){
						padding-left: 30px;
					}
					.item {
						width: 20vw;
						position: relative;
						background: $brand-color;
						margin: 0 10px;
						text-align: center;
						@include media('<=1000px'){
							margin: 0 5px;
							width: 22vw;
						}
						@include media('<=767px'){
							width: 35vw;
						}
						&::before {
							content: ' ';
							display: block;
							padding-bottom: 100%;
						}
						.outer {
							position: absolute;
							width: 100%;
							height: 100%;
							top: 0;
							left: 0;
							.inner {
								display: flex;
								align-items: center;
								height: 100%;
								text-align: center;
								img {
									margin: 0 auto;
									@include media('<=1200px'){
										max-width: 150px;
									}
									@include media('<=1000px'){
										max-width: 120px;
									}
									@include media('<=380px'){
										max-width: 100px;
									}
								}
							}
						}
					}
				}
			}
		}
		&.tech {
			margin-left: -275vw;
			@include media('<=1000px'){
				margin-left: -300vw;
			}
			&.tech2 {
				.tech-list {
					margin-left: -70vw;
					@include media('<=767px'){
						margin-left: -75vw;
					}
				}
			}
			&.tech3 {
				.tech-list {
					margin-left: -140vw;
					@include media('<=767px'){
						margin-left: -150vw;
					}
				}
			}
			&.tech4 {
				.tech-list {
					margin-left: -210vw;
					@include media('<=767px'){
						margin-left: -225vw;
					}
				}
			}
			&.tech5 {
				.tech-list {
					margin-left: -280vw;
					@include media('<=767px'){
						margin-left: -300vw;
					}
				}
			}
			&.tech6 {
				.tech-list {
					margin-left: -350vw;
					@include media('<=767px'){
						margin-left: -375vw;
					}
				}
			}
			&.tech7 {
				.tech-list {
					margin-left: -420vw;
					@include media('<=767px'){
						margin-left: -450vw;
					}
				}
			}
			&.tech8 {
				.tech-list {
					margin-left: -490vw;
					@include media('<=767px'){
						margin-left: -525vw;
					}
				}
			}
		}
	}
}