.c-wrapper {
	position: relative;
	width: 100%;
	height: 100%;
	display: block;
	@include media('<=1000px'){
		display: flex;
		flex-direction: column-reverse;
		overflow: hidden;
		flex-wrap: nowrap;
	}
	@include media('<=767px'){
		height: auto;
	}
}
.carousel-left {
	width: 40%;
	position: absolute;
	top: 0;
	left: 0;
	display: flex;
    flex-direction: column-reverse;
	transition: all 0.6s ease-in-out;
	@include media('<=1000px'){
		position: relative;
		width: 100vw;
		height: 50vh;
		flex-wrap: nowrap;
		flex-direction: row-reverse;
		top: auto !important;
	}
	@include media('<=767px'){
		height: auto;
	}
	.carousel-item {
		height: 100vh;
		display: table;
		color: #fff;
		@include media('<=1000px'){
			height: 50vh;
			display: flex;
			width: 100vw;
		}
		@include media('<=767px'){
			height: auto;
		}
		> div {
			display: table-cell;
			vertical-align: middle;
			padding-left: 150px;
			@include media('<=1200px'){
				padding-left: 100px;
			}
			@include media('<=1000px'){
				display: block;
				width: 100vw;
				text-align: center;
				padding: 0 130px;
			}
			@include media('<=767px'){
				padding: 0 30px;
			}
			h4 {
				font-size: 36px;
				text-transform: none;
				color: $brand-color;
				@include media('<=1200px'){
					font-size: 30px;
				}
				@include media('<=1000px'){
					color: #fff;
					text-shadow: 1px 1px 1px #000;
				}
				@include media('<=767px'){
					font-size: 25px;
					margin: 20px 0;
				}
				@include media('<=320px'){
					margin-top: 0;
				}
			}
			ul {
				padding: 0;
				list-style: none;
				li {
					line-height: 2em;
					@include media('<=1000px'){
						text-shadow: 1px 1px 1px #000;
						font-size: 18px;
					}
					@include media('<=767px'){
						font-size: 14px;
					}
				}
			}
		}
	}
}
.carousel-right {
	width: 60%;
	position: absolute;
	top: 0;
	right: 0;
	display: flex;
	flex-wrap: wrap;
	transition: all 0.6s ease-in-out;
	padding-top: 20vh;
	@include media('<=1000px'){
		position: relative;
		width: 100%;
		flex-direction: row;
		flex-wrap: nowrap;
		top: auto !important;
		padding-top: 150px;
		padding-left: 15vw;
	}
	@include media('<=767px'){
		padding-left: 0;
		padding-top: 100px;
	}
	.carousel-item {
		height: 60vh;
		padding-left: 60px;
		@include media('<=1200px'){
			padding-left: 20px;
		}
		@include media('<=1000px'){
			height: 100%;
			padding-left: 0;
		}
		img {
			transition: all .6s ease-in-out;
			@include media('<=1600px'){
				transform: scale(.7);
			}
			@include media('<=1200px'){
				transform: scale(.8);
				width: 100%;
			}
			@include media('<=1000px'){
				width: 70vw;
				object-fit: contain;
				transform: none !important;
			}
			@include media('<=767px'){
				width: 100vw;
				margin-left: 1vw;
			}
		}
	}
}

.work-carousel.s1 .carousel-left {
	&.list-2 {
		top: -100%;
	}
	&.list-3 {
		top: -200%;
	}
	&.list-4 {
		top: -300%;
	}
	&.list-5 {
		top: -400%;
	}
	&.list-6 {
		top: -500%;
	}
	@include media('<=1000px'){
		margin-left: 0;
	}
}
.work-carousel.s2 .carousel-left {
	&.list-2 {
		top: 0;
	}
	&.list-3 {
		top: -100%;
	}
	&.list-4 {
		top: -200%;
	}
	&.list-5 {
		top: -300%;
	}
	&.list-6 {
		top: -400%;
	}
	@include media('<=1000px'){
		margin-left: 100vw;
	}
}
.work-carousel.s3 .carousel-left {
	&.list-3 {
		top: 0;
	}
	&.list-4 {
		top: -100%;
	}
	&.list-5 {
		top: -200%;
	}
	&.list-6 {
		top: -300%;
	}
	@include media('<=1000px'){
		margin-left: 200vw;
	}
}
.work-carousel.s4 .carousel-left {
	&.list-4 {
		top: 0;
	}
	&.list-5 {
		top: -100%;
	}
	&.list-6 {
		top: -200%;
	}
	@include media('<=1000px'){
		margin-left: 300vw;
	}
}
.work-carousel.s5 .carousel-left {
	&.list-5 {
		top: 0;
	}
	&.list-6 {
		top: -100%;
	}
	@include media('<=1000px'){
		margin-left: 400vw;
	}
}
.work-carousel.s6 .carousel-left {
	&.list-6 {
		top: 0;
	}
	@include media('<=1000px'){
		margin-left: 500vw;
	}
}

.work-carousel.s1 .carousel-right {
	top: 0%;
	@include media('<=1000px'){
		margin-left: 0;
	}
}
.work-carousel.s2 .carousel-right {
	top: -60%;
	@include media('<=1000px'){
		margin-left: -70vw;
	}
	@include media('<=767px'){
		margin-left: -100vw;
	}
}
.work-carousel.s3 .carousel-right {
	top: -120%;
	@include media('<=1000px'){
		margin-left: -140vw;
	}
	@include media('<=767px'){
		margin-left: -200vw;
	}
}
.work-carousel.s4 .carousel-right {
	top: -180%;
	@include media('<=1000px'){
		margin-left: -210vw;
	}
	@include media('<=767px'){
		margin-left: -300vw;
	}
}
.work-carousel.s5 .carousel-right {
	top: -240%;
	@include media('<=1000px'){
		margin-left: -280vw;
	}
	@include media('<=767px'){
		margin-left: -400vw;
	}
}
.work-carousel.s6 .carousel-right {
	top: -300%;
	@include media('<=1000px'){
		margin-left: -350vw;
	}
	@include media('<=767px'){
		margin-left: -500vw;
	}
}

.work-carousel.s1 .carousel-right .carousel-item:nth-child(1) img,
.work-carousel.s2 .carousel-right .carousel-item:nth-child(2) img,
.work-carousel.s3 .carousel-right .carousel-item:nth-child(3) img,
.work-carousel.s4 .carousel-right .carousel-item:nth-child(4) img,
.work-carousel.s5 .carousel-right .carousel-item:nth-child(5) img,
.work-carousel.s6 .carousel-right .carousel-item:nth-child(6) img {
	transform: scale(1.2);
	@include media('<=1600px'){
		transform: scale(1);
	}
	@include media('<=1400px'){
		transform: scale(.8);
	}
	@include media('<=1200px'){
		transform: scale(1);
	}
}

